<template>
	<form class="form form--profile form-profile-settings" @submit.prevent.stop="submitForm(fields)">
		<div class="form__loader" v-if="isLoading">
			<loading-spinner />
		</div>

		<div class="form-section">

			<h1 class="form-section__title">Settings</h1>
			<p class="form-section__description">Update your settings.</p>

			<div class="form-row">
				<text-field class="form__field" label="Job Title" v-model="fields.settings.jobTitle" />
			</div>

			<template v-if="fields.permissions.arc">
				<h2 class="form-section__title">ARC Daily Emails</h2>
				<div class="form-row form-row--col-5">
					<template v-for="(day, key) in arcDays">
						<checkbox class="form__field" v-model="fields.settings.arcDaily[key]" :value="key" :key="key" :label="day" />
					</template>
				</div>
			</template>

			<div class="form__actions form__actions--horizontal">
				<a-button class="button--primary form__action" type="submit" :disabled="isLoading">Update Settings</a-button>
			</div>

		</div>

	</form>
</template>

<script>
import AButton from '@/components/AButton'
import Checkbox from '@/components/Checkbox'
import LoadingSpinner from '@/components/LoadingSpinner'
import SelectField from '@/components/SelectField'
import TextField from '@/components/TextField'
import stateList from '@/assets/stateList.json'

export default {
	name: 'FormProfile',
	components: {
		AButton,
		Checkbox,
		LoadingSpinner,
		SelectField,
		TextField,
	},
	props: {
		user: {
			type: Object,
			default: () => ({
				settings: {
					arcDaily: [],
				},
			}),
		},
	},
	data: () => ({
		isLoading: false,
		defaults: {
			address: {},
			settings: {
				arcDaily: [],
			},
		},
		fields: {},
		states: stateList,
		arcDays: {
			1: 'Monday',
			2: 'Tuesday',
			3: 'Wednesday',
			4: 'Thursday',
			5: 'Friday',
		},
	}),
	methods: {
		submitForm(fields) {
			this.isLoading = true
			this.$store.dispatch('user/updateMe', fields)
				.then(() => {
					this.$notice(`Your profile has been updated!`)
				})
				.catch((error) => {
					this.$notice(`ERROR: Could not update profile`)
				})
				.finally(() => {
					this.isLoading = false
				})
		},
	},
	watch: {
		user: {
			immediate: true,
			handler: function (userProp) {
				this.fields = {
					...this.defaults,
					...userProp,
					...{ settings: {...this.defaults.settings, ...userProp.settings }}
				}
			},
		},
	},
}
</script>

<style scope lang="scss">

</style>
