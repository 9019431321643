<template>
	<div class="partial partial--profile profile">
		<div class="partial__heading">
			<ion-icon name="person" size="large" class="partial__icon"></ion-icon>
			<h1 class="partial__title">My Profile</h1>
		</div>

		<div class="partial__body profile__body">
			<card>
				<form-profile :user="userData" />
			</card>

			<card>
				<form-email :user="userData" />
			</card>

			<card>
				<form-profile-settings :user="userData" />
			</card>

			<card>
				<form-password-reset />
			</card>
		</div>
	</div>

</template>

<script>
import Card from '@/components/Card'
import FormEmail from '@/components/FormEmail'
import FormPasswordReset from '@/components/FormPasswordReset'
import FormProfile from '@/components/FormProfile'
import FormProfileSettings from '@/components/FormProfileSettings'

export default {
	name: 'partialProfile',
	components: {
		Card,
		FormEmail,
		FormPasswordReset,
		FormProfile,
		FormProfileSettings,
	},
	metaInfo: () => ({
		title: 'My Profile',
	}),
	asyncData: async ({ store }) =>  {
		const user = await store.getters['user/me']
		return store.dispatch('user/getData', user.uid)
	},
	computed: {
		userData() {
			return this.$store.getters['user/myData']
		},
	},
}
</script>

<style scoped lang="scss">
.card {
	@include modules.gutter('margin-bottom');
}

.profile {
	&__body {
		@include modules.gutter('grid-column-gap');
		@include modules.gutter('grid-row-gap');
		display: grid;
		grid-template-columns: 1fr;


		@include modules.media-query-min-width('desktop-sm') {
			grid-template-columns: repeat(2, 1fr);
		}
	}
}
</style>
